
import {computed, defineComponent, onMounted, reactive, ref} from "vue";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import {useMetricStore} from "@/store/metric.store";
import QuickAction from "@/components/base/action/QuickAction.vue";
import KtDatatable from "@/components/kt-datatable/KTDatatable.vue";
import {getCurrentSpaceAccess, LoadPanel, MetricGroupsSelect, PinaFilterObjects} from "@/core/composite/composite";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import MetricForm from "@/views/metric/MetricForm.vue";
import MetricStatus from "@/views/metric/MetricStatus.vue";
import MetricService from "@/core/services/MetricService";
import Swal from "sweetalert2";
import ValueFormat from "@/views/metric/value/ValueFormat.vue";
import BaseSelect from "@/components/base/select/BaseSelect.vue";
import UserListSelect from "@/components/common/UserListSelect.vue";
import {useStore} from "vuex";
import UserLink from "@/views/user/UserLink.vue";
import MetricName from "@/views/metric/MetricName.vue";
import SearchHorizontal from "@/components/common/SearchHorizontal.vue";
import TeamLink from "@/views/team/TeamLink.vue";
import {useQuickActionStore} from "@/store/quick.action.store";

export default defineComponent({
  name: "List",
  components: {
    TeamLink,
    SearchHorizontal,
    MetricName,
    UserLink,
    UserListSelect,
    BaseSelect,
    ValueFormat,
    MetricStatus, MetricForm, BaseModal, KtDatatable, QuickAction
  },
  setup() {
    const vuexStore = useStore();
    const user = computed(() => vuexStore.getters.currentUser);
    const formMetricRef = reactive<typeof BaseModal>(BaseModal)
    const addMetricRef = ref<any>(null)

    const state = ref({
        name: 'Add Metric',
        metric: {
          name: '',
          type: 'NUMBER',
          groupIdRequest: '',
          requestAssigneeId: '',
          breakdownIdRequest: '',
          teamIdRequest: '',
          assignType: 'USER',
          frequency: {period: 'DAILY', frequencies: []},
          direction: 'UP',
          description: '',
          docIdRequest: '',
        },

        metricId: ''
      }
    )

    const callback = (action) => {
      if (action.args[0] === 'METRIC') {
        addMetricRef?.value?.click();
      }
    }
    const quickActionStore = useQuickActionStore();
    onMounted(() => {
      setCurrentPageBreadcrumbsWithParams("All Metrics",
        [
          {link: false, router: '', text: 'All Metrics'}
        ]
      )
    });
    quickActionStore.$onAction(callback, true)
    const store = useMetricStore();
    const page = computed(() => store.page);
    const filterObjects = ref({groupId: '', assigneeId: '', showArchived: false})
    const headers = [

      {
        name: 'Name',
        key: 'name',
        sortable: false
      },
      {
        name: 'Status',
        key: 'status',
        sortable: false
      },
      {
        name: 'Group',
        key: 'category',
        sortable: false
      },
      {
        name: 'Assigned',
        key: 'assigned',
        sortable: false
      },
      {
        name: 'Value',
        key: 'value',
        sortable: false
      },
    ]


    return {
      formMetricRef,
      state,
      filterObjects,
      page,
      headers,
      user,
      ...MetricGroupsSelect(),
      ...getCurrentSpaceAccess(),
      ...LoadPanel(),
      addMetricRef,
      ...PinaFilterObjects(store, filterObjects.value, ['createdBy', 'group', 'assignee', 'breakdown', 'team'])
    }
  },
  methods: {
    searchEvent() {
      this.updateFilterObject(this.filterObjects);
    },
    discardEvent() {
      this.filterObjects = {groupId: '', assigneeId: '', showArchived: false}
      this.updateFilterObject(this.filterObjects);
    },
    add() {
      this.state.metric = {
        name: '',
        type: 'NUMBER',
        groupIdRequest: '',
        requestAssigneeId: '',
        breakdownIdRequest: '',
        frequency: {period: 'DAILY', frequencies: []},
        direction: 'UP',
        teamIdRequest: '',
        assignType: 'USER',
        description: '',
        docIdRequest: '',
      }
      this.state.metricId = '';
      this.state.name = 'Create Metric'
      this.formMetricRef.showBaseModal()
    },

    onSave() {
      this.paginationDataLoad()
      const modal = this.$refs.formMetricRef as typeof BaseModal;
      modal.hideBaseModal();
    },

    onStart(metric) {
      Swal.fire({
        heightAuto: false,
        onOpen: () => {
          Swal.showLoading()
        },
      });
      MetricService.start(metric.id).then(() => {
        this.paginationDataLoad()
      }).finally(() => {
        Swal.close();
      })
    },
    onStop(metric) {
      Swal.fire({
        heightAuto: false,
        onOpen: () => {
          Swal.showLoading()
        },
      });
      MetricService.stop(metric.id).then(() => {
        this.paginationDataLoad()
      }).finally(() => {
        Swal.close();
      })
    },
    onComplete(metric) {
      Swal.fire({
        heightAuto: false,
        onOpen: () => {
          Swal.showLoading()
        },
      });
      MetricService.complete(metric.id).then(() => {
        this.paginationDataLoad()
      }).finally(() => {
        Swal.close();
      })
    },
    onDeleteEntity(metric) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        showLoaderOnConfirm: true,
        heightAuto: false,
        preConfirm: async () => {
          await MetricService.delete(metric.id).then(() => {
            this.paginationDataLoad()
          })
        }
      })
    },
  }
})
